.Footer_Grid{
    background-color: #000a26;
    color:white;
    margin-top: 10px;
    margin-bottom:0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: auto;
    }
.Footer_a{
    color: white;
    text-decoration: none;
    margin-bottom: 1rem;
    font-size: 16px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.Footer_a_small{
    color: white;
    text-decoration: none;
    font-size: 12px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.Footer_titles{
    font-size: 22px; 
}

.Footer_H4{
    color: white;
    font-size: 16px;
    display:flex;
    align-items: center;
    justify-content: center;
}


.Footer_p{
    font-size: 16px;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}
.Footer_p_small{
    font-size: 12px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.Footer_Link{
    margin-bottom: 5;
}
