.carousel {
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode:darken;
    height: 350px;
    width: 100%;
    margin-top: 5px;
}

@media (min-width :370px) and (max-width :664px) {
    .carousel {
        background-size: cover;
        background-position: center right;
        height: 400px;
    }
}
@media (min-width :414px) and (max-width :896px) {
    .carousel {
        background-size: cover;
        background-position: center right;
        height: 400px;
    }
}

@media (min-width :370px) and (max-width :664px) and (orientation:landscape) {
    .carousel {
        background-size: cover;
        background-position: center;
        height: 350px;
    }
}

@media (min-width :600px) and (max-width :664px) {
    .carousel {
        background-size: cover;
        background-position: center;
        height: 350px;
    }
}
@media (min-width :660px) and (max-width :985px) and (orientation:landscape) {
    .carousel {
        background-size: cover;
        background-position: center;
        height: 350px;
    }
}

@media (min-width :782px) and (max-width :899px) {
    .carousel {
        background-size: cover;
        background-position: center;
        height: 350px;
    }
}

@media (min-width :1089px) and (max-width :1199px) {
    .carousel {
        background-size: cover;
        background-position: center;
        height: 350px;
    }
}

@media (min-width :1393px){
    .carousel {
        background-size: cover;
        background-position: center;
        height: 500px;
    }
}

                    