.blog-text-normal {
    font-size: 18px;
    line-height: normal;
}

.blog-text-preview {
    font-size: 16px;
    line-height: normal;
}
.blog-text-intro{
    font-size: 18px;
    line-height: normal;
}

@media (max-width :369px) {
    .blog-text-intro{
        font-size: 16px;
        line-height: normal;
    }
}

@media (width >=370px) and (width <=437px) {
    .blog-text-intro{
        font-size: 16px;
        line-height: normal;
    }
}

@media (min-width :438px) and (max-width :649px) {
  
    .blog-text-intro{
        font-size: 14px;
        line-height: normal;
    }

}

@media (min-width :650px) and (max-width :665px) {
   
    .blog-text-intro{
        font-size: 14px;
        line-height: normal;
    }
}


@media (min-width :665px) and (max-width :788px) {

    .blog-text-intro{
        font-size: 14px;
        line-height: normal;
    }
}

@media (min-width :788px) and (max-width :900px) {
    .blog-text-intro{
        font-size: 16px;
        line-height: normal;
    }

}



@media (min-width :900px) and (max-width :1200px) {
    .blog-text-intro{
        font-size: 16px;
        line-height: normal;
    }
}

@media (min-width :1100px) and (max-width :1200px) {
    .blog-text-intro{
        font-size: 18px;
        line-height: normal;
    }
}

@media (min-width :1200px) and (max-width :1405px) {
    .blog-text-intro{
        font-size: 18px;
        line-height: normal;
    }
}
