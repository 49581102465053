.blog-title-b {
    font-weight: bold;
    font-size: 45px;
    margin: 0;
}

.blog-img-b {
    width: 500px;
    height: auto;
    border-radius: 8px;
    /* border-radius: 21% 79% 46% 54% / 68% 36% 64% 32% ; */
}


@media (max-width :369px) {
    .blog-title-b {
        font-size: 25px;
    }

    .blog-img-b {
        width: 100%;
        height: auto;
    }
}

@media (width >=370px) and (width <=437px) {
   
    .blog-title-b {
        font-size: 25px;
    }

    .blog-img-b {
        width: 100%;
        height: auto;
    }
}

@media (min-width :438px) and (max-width :600px) {
  

    .blog-title-b {
        font-size: 25px;
    }

    .blog-img-b {
        width: 100%;
        height: auto;
    }

}

@media (min-width :600px) and (max-width :665px) {
   

    .blog-title-b {
        font-size: 25px;
    }

    .blog-img-b {
        width: 230px;
        height: auto;
    }

}


@media (min-width :665px) and (max-width :788px) {

    .blog-title-b {
        font-size: 25px;
    }

    .blog-img-b {
        width: 280px;
        height: auto;
    }
}

@media (min-width :788px) and (max-width :900px) {

    .blog-title-b {
        font-size: 28px;
    }

    .blog-img-b {
        width: 300px;
        height: auto;
    }

}



@media (min-width :900px) and (max-width :1200px) {
    .blog-title-b {
        font-size: 30px;
    }

    .blog-img-b {
        width: 350px;
        height: auto;
    }
}

@media (min-width :1100px) and (max-width :1200px) {
    .blog-title-b {
        font-size: 30px;
    }

    .blog-img-b {
        width: 350px;
        height: auto;
    }
}

@media (min-width :1200px) and (max-width :1405px) {
    .blog-title-b {
        font-size: 40px;
    }

    .blog-img-b {
        width: 450px;
        height: auto;
    }
}