.carousel-img{
    max-width: 100%;
    height:auto;
}

.carousel-title{
    font-size: 55px;
    margin: 0;
    color:#000a2d;
    font-weight: 700;
    font-family: Roboto,Arial,sans-serif;
    letter-spacing: -1px;
    line-height: 0.8;
}
.carousel-sub{
    font-size: 32px;
    margin: 0;
    color:#000a2d;
    font-weight: 600;
    letter-spacing: -1px;
}

.carousel-des{
    font-size: 23px;
    margin: 0;
    color:#000a2d;
    font-weight: 400;
    letter-spacing: -1px;
}



@media (max-width :369px){
    .carousel-img{
        height:135px;
    }
    .carousel-title{
        font-size: 12px;
        letter-spacing: -1px;
    }
    .carousel-sub{
        font-size: 10px;
        margin: 0;
        color:#000a2d;
        font-weight: 500;
        letter-spacing: -1px;
    }
    
    .carousel-des{
        font-size: 10px;
        margin: 0;
        color:#000a2d;
        font-weight: 400;
    }
}
@media (min-width :370px) and (max-width :437px){
    .carousel-img{
        height:135px;
    }
    .carousel-title{
        font-size: 18px;
        letter-spacing: -1px;
    }
    .carousel-sub{
        font-size: 15px;
        margin: 0;
        color:#000a2d;
        font-weight: 500;
        letter-spacing: -1px;
    }
    
    .carousel-des{
        font-size: 12px;
        margin: 0;
        color:#000a2d;
        font-weight: 400;
    }
}

 @media (min-width :438px) and (max-width :664px) {
    .carousel-img{
        height:auto;
    }
    .carousel-title{
        font-size: 18px;
        letter-spacing: -1px;
    }
    .carousel-sub{
        font-size: 15px;
        margin: 0;
        color:#000a2d;
        font-weight: 500;
        letter-spacing: -1px;
    }
    
    .carousel-des{
        font-size: 13px;
        margin: 0;
        color:#000a2d;
        font-weight: 400;
    }
    
}
 

 @media (min-width :665px)  and (max-width :787px)   {
    .carousel-title{
        font-size: 35px;
        letter-spacing: -1px;
    }
    .carousel-sub{
        font-size: 20px;
        margin: 0;
        color:#000a2d;
        font-weight: 500;
        letter-spacing: -1px;
    }
    
    .carousel-des{
        font-size: 17px;
        margin: 0;
        color:#000a2d;
        font-weight: 400;
    }
}  

@media (min-width :788px)  and (max-width :965px)   {
    .carousel-title{
        font-size: 40px;
        letter-spacing: -2px;
    }
    .carousel-sub{
        font-size: 20px;
        margin: 0;
        color:#000a2d;
        font-weight: 500;
        letter-spacing: -1px;
    }
    
    .carousel-des{
        font-size: 17px;
        margin: 0;
        color:#000a2d;
        font-weight: 400;
    }
} 
@media (min-width :966px)  and (max-width :1196px)   {
    .carousel-sub{
        font-size: 23px;
        margin: 0;
        color:#000a2d;
        font-weight: 500;
        letter-spacing: -1px;
    }
    
    .carousel-des{
        font-size: 20px;
        margin: 0;
        color:#000a2d;
        font-weight: 400;
    }
}

/* @media (min-width :1197px)  and (max-width :1500px)   {
    .carousel-title{
        font-size: 55px;
        letter-spacing: -2px;
    }
} */