.calculator-title {
    font-size: 45px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    font-family: Roboto,Arial,sans-serif;
    
}

.calculator-subtitle {
    font-size: 22px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    color: #ff1719;
    font-family: Roboto,Arial,sans-serif;
}

.calculator-plazo {
    font-size: 30px;
}

@media (width >=370px) and (width <=437px) {
    .calculator-title {
        font-size: 35px;
    }
    .calculator-subtitle {
        font-size: 18px;
    }
}


@media (width <= 321px) {
    .calculator-title {
        font-size: 25px;
    }
    .calculator-subtitle {
        font-size: 12px;
    }
}

@media (width >=370px) and (width <=437px) {
    .calculator-title {
        font-size: 35px;
    }
    .calculator-subtitle {
        font-size: 18px;
    }
}

@media (min-width :438px) and (max-width :649px) {
    .calculator-title {
        font-size: 38px;
    }
    .calculator-subtitle {
        font-size: 18px;
    }
}

